<div class="grid grid-cols-12 gap-4">
  <div [ngClass]="isShowFilters ? 'visible' : 'hidden'"  [@filterBar] class="col-span-12 sm:col-span-2">
    <div
      class="h-full absolute z-50 md:static flex flex-col col-span-2 bg-gray-100 p-4"
    >
      <div class="flex items-start justify-start relative">
        <button
          class="absolute -right-10 text-white text-sm bg-secondary-400 hover:bg-primary-500 hover:text-gray-50 p-2 rounded-md hover:shadow-inner duration-300"
          (click)="isShowFilters = !isShowFilters"
        >
          <fa-icon [icon]="faClose"></fa-icon>
        </button>
      </div>

      <app-domain-area-filter (propagate)="onCategoryCheck()" class="mt-2">
      </app-domain-area-filter>
    </div>
  </div>

  <button
    *ngIf="!isShowFilters"
    class="flex justify-end z-50 fixed left-0 top-16 text-white text-sm bg-secondary-400 hover:bg-primary-500 hover:text-gray-50 p-2 rounded-r hover:shadow-inner duration-300"
    (click)="isShowFilters = !isShowFilters"
  >
    <fa-icon [icon]="faFilter"></fa-icon>
  </button>

  <div
    class="
    col-span-12
    {{ !isShowFilters ? '' : 'sm:col-span-10' }}"
  >
    <app-header
      [label]="'pages-headers.marketplace' | translate"
      [hasSearchBar]="true"
      [overLabel]="'pages-headers.pages-names.marketplace' | translate"
      (search)="onPerPageChange()"
      (clear)="onPerPageChange()"
      photo="assets/img/backgrounds/services.jpg"
    ></app-header>

    <app-pagination
      classes="px-4"
      [isLoading]="!(services$ | async)"
      (currentPageChange)="onPageChange()"
      (perPageChange)="onPerPageChange()"
    ></app-pagination>

    <div
      *ngIf="services$ | async; else serviceLoading"
      [@queryAnimation]="isShowFilters ? 'openFilter' : 'closedFilter'"
      class="min-h-[80vh] grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6 gap-4 px-4"
    >
      <ng-container
        *ngFor="let service of services$ | async"
        class="col-span-8"
      >
        <app-service-catalogue-card
          class=""
          (onCardClick)="onCardClick(service.identifier)"
          [description]="service.description | truncate : 100"
          [service]="service"
        ></app-service-catalogue-card>
      </ng-container>
    </div>
    <ng-template #serviceLoading>
      <app-loading
        label="{{ 'actions.loading.services' | translate }}"
      ></app-loading>
    </ng-template>

    <app-pagination
      *ngIf="pageTotalResults > 0"
      [isLoading]="!(services$ | async)"
      classes="px-4"
      (currentPageChange)="onPageChange()"
      (perPageChange)="onPerPageChange()"
    ></app-pagination>
  </div>
</div>
